
#app {
    text-align: center;
    font-family: 'roboto', sans-serif;
    overflow: hidden;
    background: #f5f6f7;
}

#content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    min-height: 78vh;
    margin-top: 12vh;
    margin-bottom: 4vh;
}

#header {
    height: 5vh;
    width: 100vw;
}

body {
    overflow-y: scroll;
    overflow-x: hidden;
}

.circular-progress {
    position: absolute;
    left: 50%;
    top: 40%;
}

.user-info {
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.email {
    margin-left: 0.5vw;
    margin-right: 3vw;
    font-size: 15px;
}
